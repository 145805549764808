<template>
  <div :class="{'pointer-events-none opacity-50': !selectedMaker}">
    <div class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t border-b">
      <div class="flex border items-center">
        <vs-input v-model="search" @keyup.enter="getInitData" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Search Approver" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
      </div>
      <div class="email__actions flex items-center flex-wrap justify-between p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
        <div class="flex w-full justify-between items-center py-2">
          <div class="flex items-center px-2">
            <span v-if="loading" class="text-right text-xs">Loading...</span>
            <span v-else class="text-right text-xs font-semibold">Approver</span>
          </div>
          <div class="flex items-center space-x-3">
            <feather-icon @click="showModalAdd" icon="PlusIcon" class="cursor-pointer hover:text-primary" svg-classes="h-4 w-4"/>
            <feather-icon @click="refresh" icon="RefreshCwIcon" class="cursor-pointer hover:text-primary" svg-classes="h-4 w-4"/>
          </div>
        </div>
      </div>
      <component v-if="selectedMaker" :is="scrollbarTag" class="email-content-scroll-area" :settings="scrollbarSettings" ref="mailListPS">
        <div v-for="(item, index) in items" :key="index" class="flex px-3 py-3 space-x-2 w-full justify-between zs-center border border-solid border-t-0 border-b border-l-0 border-r-0 d-theme-border-grey-light">
          <div class="flex items-center space-x-3">
            <h4 class="pl-2 pr-4 border border-solid border-t-0 border-b-0 border-l-0 border-r d-theme-border-grey-light" :class="{'text-danger': errorMessageItem(index)}">{{ item.no_urut }}</h4>
            <div class="hover:font-medium">
              <div class="flex items-center space-x-1">
                <feather-icon :icon="item.is_approver_specific_user ? 'UserIcon' : 'ShieldIcon'" svg-classes="h-4 w-4"/>
                <p class="text-sm font-semibold">{{ item.is_approver_specific_user ? item.nama_user_approver : item.nama_role_approver }}</p>
              </div>
              <div class="flex items-center space-x-2">
                <span class="text-xs text-white" :class="'bg-' + (item.is_approver_specific_user ? 'blue' : 'green')">{{ item.is_approver_specific_user ? 'USER' : 'ROLE' }}</span>
                <p class="text-sm">{{ item.is_approver_specific_user ? item.username_user_approver : item.kode_role_approver }}</p>
              </div>
              <span v-if="errorMessageItem(index)" class="font-medium text-danger text-xs animate-pulse">{{ errorMessageItem(index) }}</span>
            </div>
          </div>
          <div class="flex items-center space-x-2 pr-2">
            <feather-icon icon="EditIcon" svg-classes="h-3 w-3" class="hover:text-warning" @click="showModalEdit(item)"/>
            <feather-icon icon="TrashIcon" svg-classes="h-3 w-3" class="hover:text-danger" @click="confirmDelete(item)"/>
          </div>
        </div>
      </component>
    </div>

    <!--modals-->
    <ApproverAdd :isActive.sync="modalAdd.active" :selectedMaker="modalAdd.selectedMaker" :defaultNoUrut="maxNoUrut + 1" @success="refresh"/>
    <ApproverEdit :isActive.sync="modalEdit.active" :initData="modalEdit.initData" @success="refresh"/>
  </div>
</template>

<script>
import SystemApprovalRepository from '@/repositories/general/system-approvals-repository'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import _ from 'lodash'
import ApproverAdd from '@/views/pages/general/system_approvals/approver/ApproverAdd'
import ApproverEdit from '@/views/pages/general/system_approvals/approver/ApproverEdit'

export default {
  name: 'ApproverSection',
  components: {
    ApproverEdit,
    ApproverAdd,
    VuePerfectScrollbar
  },
  computed: {
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    scrollbarSettings () {
      return {
        maxScrollbarLength: 100,
        wheelSpeed: 0.8
      }
    },
    dataMakerSection () {
      return this.$store.state.general.systemApprovals.makerSection
    },
    selectedMaker () {
      return this.dataMakerSection.selected
    },
    maxNoUrut () {
      const listNoUrut = _.map(this.items, item => item.no_urut)
      return _.max(listNoUrut) || 0
    }
  },
  watch: {
    selectedMaker (data) {
      this.items = []
      if (data) {
        this.getInitData()
      }
    },
    items: {
      deep: true,
      handler (value) {
        const data = { items: value }
        this.commitDataToStore(data)
      }
    }
  },
  data () {
    return {
      search: null,
      loading: false,
      items: [],
      modalAdd: {
        selectedMaker: {},
        active: false
      },
      modalEdit: {
        initData: {},
        active: false
      }
    }
  },
  methods: {
    showModalAdd () {
      this.modalAdd.selectedMaker = this.selectedMaker
      this.modalAdd.active = true
    },

    showModalEdit (data) {
      this.modalEdit.initData = data
      this.modalEdit.active = true
    },

    getInitData () {
      this.loading = true

      const params = {
        type: this.selectedMaker.type,
        min_amount: this.selectedMaker.min_amount,
        max_amount: this.selectedMaker.max_amount,
        id_role_creator: this.selectedMaker.id_role_creator,
        id_user_creator: this.selectedMaker.id_user_creator,
        search: this.search
      }
      SystemApprovalRepository.getApprovers(params)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    refresh () {
      this.getInitData()
    },

    confirmDelete (item) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(item) }
      })
    },

    delete (item) {
      const params = {
        id_system_approval: item.id_system_approval
      }
      SystemApprovalRepository.deleteApprover(params)
        .then(response => {
          this.refresh()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    errorMessageItem (index) {
      const prevItem = index === 0 ? this.items[index] : this.items[index - 1]
      const currentItem = this.items[index]
      const noUrutPrevItem = prevItem.no_urut
      const noUrutCurrentItem = currentItem.no_urut

      if ((noUrutCurrentItem !== noUrutPrevItem) && (noUrutPrevItem !== noUrutCurrentItem - 1)) {
        return `Approver dengan no urut ${noUrutCurrentItem - 1} belum ada.`
      }

      return null
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('general/systemApprovals/SET_APPROVER_SECTION', payload)
    })
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
