const getDefaultState = () => {
  return {
    approvalTypeSection: {
      selected: {
        parent: null,
        child: null
      }
    },
    makerSection: {
      selected: null
    },
    approverSection: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_APPROVAL_TYPE_SECTION (state, data) {
      state.approvalTypeSection = data
    },
    SET_MAKER_SECTION (state, data) {
      state.makerSection = data
    },
    SET_APPROVER_SECTION (state, data) {
      state.approverSection = data
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
